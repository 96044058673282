<template>
	<div></div>
</template>

<script>

import { User } from '@/shared/http'; 

export default {
	name: 'Cikis',
	data() {
		return {

		}
	},
	mounted() {
		localStorage.removeItem('currentAdmin')
		this.$router.push('giris')
	}
}
</script>
